import { Auth } from 'aws-amplify';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export const passwordPolicy = /[!@#$%^&*()+\-={}[\]:;'<>,._~\\]/;
export const allowedSymbols = "! @ # $ % ^ & * ( ) + - = { } [ ] : ; ' < > , . / ? _ ~ ¥";

export const toBlobPdf = (base64: string, mime_ctype: string): Blob => {
  // 日本語の文字化けに対処するためBOMを作成する。
  var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  var bin = atob(base64.replace(/^.*,/, ''));
  var buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  var blob = new Blob([bom, buffer.buffer], {
      type: mime_ctype,
  });
  return blob;
};

export const toBlobCsv = (base64: string, mime_ctype: string): Blob => {
    const byteArray = new Uint8Array(atob(base64).split('').map(function (char) {
      return char.charCodeAt(0);
    }));

    var bin = atob(base64);
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([byteArray], {
        type: mime_ctype,
    });
    return blob;
};

// base64エンコードされたzip形式のファイルをblob型に変換する
export const toBlobZip = (base64: string): Blob => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], {
    type: 'application/zip'
  });
  return blob;
};

// S3からPDF取得　
export const getCustomerManualPdf = async (fileName: string) => {
  axios.request({
      url: baseURL + "/loginpdf",
      method: "get",
      headers: { 
        Accept: "application/pdf"
      },
      params: {
        'pdf_file_name': `info/${fileName}`
      }
  })
  .then((response) => {
    const blob: Blob = toBlobPdf(response.data, "application/pdf");
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    console.log(url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  })
  .catch((error) => {
    console.log(error);
  });
};

// トークン取得
export const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};
